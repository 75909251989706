/* Component Dependencies */
var propertyMessageTemplate = require('templates/propertyMessage.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'propertyMessage',
  template: {
    'propertyMessage': propertyMessageTemplate
  }
});
